import React, { Component } from "react";
import ContactButtons from "./ContactButtons.js";

class Home extends Component {
	render() {
		return (
			<div className='home-container section-container' id='home'>
				<div className='home-intro'>
					<div className='home-intro-name-container'>
						<div className='home-intro-name'>Hi, I'm</div>
						<span className='home-intro-name'>Jordan Suero.</span>
					</div>
					<div className='home-name'>
						Software Engineer based in Portland, OR
					</div>
					<div className='home-header'>I love donuts and Vim.</div>
				</div>
				<ContactButtons />
			</div>
		);
	}
}

export default Home;
